#skills {
    background: #f8f8f8;
    overflow: hidden;
    .skills-thumb {

        img {
            width: 100%; } }


    .skills {
        padding: 40px 0 0;
        text-align: center;


        p {
            margin-bottom: 50px;
            font-size: 16px; } } }

.skills-thumb {
    background-size: cover;
    height: 500px; }


.pie-title-center {
    display: inline-block;
    position: relative;
    text-align: center;
    margin: 0 15px; }

.pie-value {
    display: block;
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    height: 40px;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -20px;
    line-height: 40px; }

.skill-grid {
    width: 25%;
    float: left; }

/*=========  Skills Two  ==========*/

#skills.skills-two {
    padding: 100px 0;
    background-size: cover;
    background: transparent;

    .skills-title {
        font-weight: 700;
        text-transform: uppercase; }


    h4 {
        font-size: 18px;
        margin-bottom: 40px; } }

.client {
    .clients-items {
        .item {
            float: left;
            width: 29%;
            margin: 10px;
            background: $color_theme;

            img {
                width: 100%; } } } }

.skills-wrapper {
    max-width: 550px;
    margin: 0 auto;
    top: 50%; }

.skills-heading {
    span {
        font-size: 16px;
        font-weight: 700;
        color: #111111; }

    p {
        color: #000;
        float: right;
        margin-bottom: 0; } }

progress {
    border: 0;
    height: 15px;
    width: 100%;
    margin-bottom: 10px; }

progress::-webkit-progress-bar {
    background-color: #eaeaea; }

progress::-webkit-progress-value {
    background-color: $color_theme;
    height: 15px; }

.skill-item {
    float: left; }

/*=========  Skills Four  ==========*/
#skills.skills-four {
    padding-top: 100px;

    .skills-wrapper {
        margin-top: 30px; }

    .section-title {
        &:after {
            transform: translateX(0);
            left: 0; } }

    h4 {
        margin-bottom: 20px; }

    > p {
        margin-bottom: 20px; }

    .skills-thumb {
        max-width: 350px;
        width: 100%;
        height: auto; }

    #skills-inner {
        margin-top: 50px; } }


$secondaryColor : #389ba6;
$mainColor : #D00463;
$sizeContent : 130px;
$strokeWidth : 8;
$strokeLineCap : round;


.ProgressBar,
.ProgressBar-contentCircle {
    display: table;
    height: $sizeContent;
    position: relative;
    width: $sizeContent;
    margin: 0 auto; }



.ProgressBar-circle,
.ProgressBar-background {
    //animation: progress 1s
    fill: none; // background color
    stroke: $color_theme; // path color
    stroke-width: $strokeWidth; // path size
    stroke-linecap: $strokeLineCap;
    stroke-dasharray: 0; // circonférence du cercle
    stroke-dashoffset: 0; // longeur du cercle
    position: relative;
    z-index: 10; }

.ProgressBar-background {
    stroke: #ccc; // path color
    stroke-width: $strokeWidth - 1;
    z-index: 0; }

.ProgressBar-percentage {
    color: #000;
    font-size: 30px;
    text-align: center;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    top: 50px; }


/************************/
/* structure de la page */
/************************/
.Content {
    height: 270px;
    margin: -135px 0 0 0;
    position: absolute;
    top: 50%;
    width: 100%; }

.List {
    display: flex;
    list-style: none;
    margin: 10px auto;
    padding: 0px;
    width: $sizeContent * 4;
    height: $sizeContent; }


.List-item {
    width: 150px;
    display: inline-block;
    margin-bottom: 40px; }


.Title {
    text-align: center; }

.circle-wrapper {
    width: 70%;
    min-height: 150px;
    height: 100%;
    margin: 0 auto; }

.circle-item {
    position: relative;

    h3 {
        position: absolute;
        top: 140px;
        font-size: 18px;
        text-transform: uppercase;
        color: #000;
        width: 100%; } }
