.countup {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-size: cover;
  text-align: center;

  .section-title {
    color: #FFF;
    margin-bottom: 60px;

    &:after {
      left: 50%;
      transform: translateX(-50%); } } }
.counter-box {
  border: 3px solid $color_theme;
  padding: 15px 0;
  background: rgba(0,0,0, 0.5);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 20px;


  .count {
    i {
      font-size: 50px;
      color: $color_theme;
      margin-bottom: 25px; }

    h2 {
      font-size: 50px;
      color: #FFF;
      font-weight: 700; }

    p {
      color: #FFF;
      font-size: 16px;
      font-weight: 300; } } }
