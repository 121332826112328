#service {
    padding: 100px 0 0;
    text-align: center;
    background: #FFF;

    p {
        margin-bottom: 80px; } }

.service-items {
    margin-top: 50px; }

.service-item {
    text-align: left;
    margin-bottom: 40px;
    cursor: pointer;
    span {
        float: left;
        height: 80px;
        width: 80px;
        margin: 0 auto;
        text-align: center;
        line-height: 90px;
        position: relative;
        box-shadow: 3px $color_theme;
        color: #FFF;
        box-shadow: 0 0 0 3px #000;
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        transition: color 0.3s;
        font-size: 40px;
        border-radius: 50%;
        z-index: 10;

        &:after {
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '';
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
            top: -2px;
            left: -2px;
            padding: 2px;
            z-index: -1;
            background: #000;
            -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
            -moz-transition: -moz-transform 0.3s, opacity 0.3s;
            transition: transform 0.3s, opacity 0.3s;
            border-radius: 50%; } }

    &.item-right {
        text-align: right;
        span {
            float: right; }


        .service-content {
            margin-left: 0;
            margin-right: 100px; } }



    .service-content {
        margin-left: 100px;

        h3 {
            color: #000;
            text-transform: uppercase;
            font-size: 20px;
            transition: all 0.3s ease-in-out;
            font-weight: 700; } }


    &:hover {
        span {
            box-shadow: 0 0 0 3px $color_theme;
            &:after {
                transform: scale(0.85);
                background: $color_theme; } }


        .service-content {
            h3 {
                color: $color_theme; } } } }


.service-thumb {
    max-width: 450px;
    img {
        width: 100%; } }


/*=========  Service Two  ==========*/
.ser-items-two {
    .service-item {
        background: #f5f5f5;
        padding: 10px;
        box-shadow: 0 10px 25px rgba(0,0,0,0.15);
        margin-bottom: 50px;
        span {
            border-radius:  4px;

            &:after {
                border-radius:  4px; } }

        .service-content {
            p {
                margin: 0 !important;
                line-height: 24px; } } } }

/*=========  Service Three  ==========*/
#service {
    &.service-three {
        background: #f5f5f5;
        padding: 70px 0;
        overflow: hidden;

        .section-title {
            margin-bottom: 50px; } } }

.ser-items-three {
    .service-item {
        padding: 40px 20px 20px 20px;
        text-align: center;
        background: #FFF;
        box-shadow: 0 10px 30px rgba(50,50,50, 0.1);


        i {
            color: #000;
            font-size: 60px;
            margin-bottom: 20px;
            display: block;
            transition: all 0.3s ease-in-out; }

        h3 {
            font-size: 24px;
            font-weight: 600;
            color: #000;
            padding-bottom: 10px;
            position: relative;
            transition: all 0.4s ease-in-out;
            margin-bottom: 0;

            &:after {
                position: absolute;
                width: 0;
                left: 50%;
                bottom: 0;
                height: 3px;
                content: '';
                background: $color_theme;
                transform: translateX(-50%);
                transition: all 0.5s ease-in-out; } }

        p {
            margin-bottom: 0 !important; }

        &:hover {

            i {
                transform: translateY(-20px);
                color: $color_theme; }

            h3 {
               transform: translateY(-20px);
               color: $color_theme;

               &:after {
                    width: 70px; } } } } }
