#template {
	text-align: center;
	color: #FFF;
	padding: 100px 0;
	background-size: cover; }

.template-content {
	h2 {
		text-transform: uppercase;
		font-size: 30px;
		margin-top: 0;
		margin-bottom: 30px;
		font-weight: 600;
		letter-spacing: 0.1em; }


	p {
		font-size: 16px;
		margin-bottom: 20px;
		letter-spacing: 0.05em; } }
