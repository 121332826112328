#pricing {
  padding: 100px 0;
  text-align: center;
  background: #f5f5f5;

  >p {
    font-size: 16px;
    margin-bottom: 50px; } }


.single-pricing {
  margin: 0 auto 30px;
  max-width: 430px;
  background: #fff;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.3);

  .pricing-head {
    padding: 15px 0;
    background: $color_theme;

    h4 {
      color: #000;
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 800;
      position: relative;
      display: inline-block;
      padding-bottom: 10px;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        background: #000;
        height: 3px;
        width: 50%;
        transform: translateX(-50%); } }

    h2 {
      font-size: 52px;
      font-weight: 900;
      color: #FFF;
      margin-top: 15px;
      margin-bottom: 0;
      line-height: 40px; } }



  .pricing-data {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 14px 0;
      font-size: 16px;
      font-weight: 500;

      &:nth-child(odd) {
        background: #f8f8f8; } } }

  .pricing-btn {
    background: $color_theme;
    padding: 20px 0;
    .gp-btn {
      border: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #FFF;
        color: #000; } } } }



/*=========  Pricing Two  ==========*/
#pricing {
  &.pricing-two {
    background-size: cover;
    background-attachment: fixed;

    .section-title {
      color: #FFF; }

    p {
      color: #FFF; }


    .single-pricing {
      background: #fff;
      padding: 5px;
      margin: 5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      max-width: 400px;

      .pricing-head {
        background: #000;
        padding-top: 0;

        h4 {
          display: block;
          background: $color_theme;
          margin: 0;
          padding: 20px 0;
          color: #FFF;

          &:after {
            display: none; } }

        h2 {
          margin-top: 25px; }

        p {
          color: $color_theme;
          margin-bottom: 0; } }

      .pricing-btn {
        .gp-btn {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.05em;

          &:hover {
            background: #FFF !important; } } }


      &:hover {
        transform: scale(1.04); } } } }


