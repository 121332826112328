$primary: #3F51B5;
$dark-primary: #303F9F;
$light-primary: #C5CAE9;
$text: #FFFFFF;
$primary-text: #212121;
$secondary-text: #757575;
$accent: #FF4081;



.exprience {

    position: relative;
    padding: 50px 0;
    text-align: center;

    &::before {
        content: '';
        background: #f5f5f5;
        width: 5px;
        height: 60%;
        position: absolute;
        left: 50%;
        top: 22%;
        transform: translateX(-50%); }

    .section-title {
        margin-bottom: 50px; }

    .year {
        position: absolute;
        top: 46px;

        span {
            font-weight: 700;
            color: $color_theme;
            font-size: 18px;
            text-transform: uppercase; } } }

.timeline-item {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding: 20px;

    .present {
        &.year {
            left: 40% !important; } }

    &:nth-child(even) {

        .timeline-content {
            float: right;
            padding: 20px;


            .date {
                right: auto;
                left: 0; }


            &::after {
                content: '';
                position: absolute;
                border-style: solid;
                width: 0;
                height: 0;
                top: 30px;
                left: -15px;
                border-width: 10px 15px 10px 0;
                border-color: transparent #f5f5f5 transparent transparent; } }


        .year {
            left: 44%; } }



    &:nth-child(odd) {
        .year {
            right: 44%; } }



    &::after {
        content: '';
        display: block;
        clear: both; } }

.timeline-content {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
    text-align: left;

    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        right: -15px;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent #f5f5f5; } }



.timeline-img {
    width: 30px;
    height: 30px;
    background: $color_theme;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px; }



// .timeline-card
//     padding: 0!important

//     p
//         padding: 0 20px


//     a
//         margin-left: 20px





.timeline-content {
    h3 {
        font-size: 20px;
        span {
            color: $color_theme; } }

    p {
        font-size: 12px;
        margin-bottom: 0; } }


@media screen and (max-width: 768px) {

    .timeline {

        &::before {
            left: 50px; }


        .timeline-img {
            left: 50px; }


        .timeline-content {
            max-width: 100%;
            width: auto;
            margin-left: 70px; }


        .timeline-item {

            &:nth-child(even) {

                .timeline-content {
                    float: none; } }




            &:nth-child(odd) {

                .timeline-content {

                    &::after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        width: 0;
                        height: 0;
                        top: 30px;
                        left: -15px;
                        border-width: 10px 15px 10px 0;
                        border-color: transparent #f5f5f5 transparent transparent; } } } } } }








