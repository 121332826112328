/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  Feature
  --------------------------------------------------------------*/
@import "feature";

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ##  Template
  --------------------------------------------------------------*/
@import "template";

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
@import "team";

/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
@import "skills";

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
@import "countup";

/*--------------------------------------------------------------
  ##  Recent Work
  --------------------------------------------------------------*/
@import "recent-work";

/*--------------------------------------------------------------
  ##  follow
  --------------------------------------------------------------*/
@import "follow";

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
@import "service";

/*--------------------------------------------------------------
  ##  like
  --------------------------------------------------------------*/
@import "like";

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
@import "pricing";

/*--------------------------------------------------------------
  ##  Brand
  --------------------------------------------------------------*/
@import "brand-slider";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import "testimonial";

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
@import "blog";

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
@import "contact";

/*--------------------------------------------------------------
  ##  Experience
  --------------------------------------------------------------*/
@import "experience";

/*--------------------------------------------------------------
  ##  Work Process
  --------------------------------------------------------------*/
@import "work-process";

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
@import "newsletter";

/*--------------------------------------------------------------
  ##  Blog Page
  --------------------------------------------------------------*/
@import "blog-page";

/*--------------------------------------------------------------
  ##  Start
  --------------------------------------------------------------*/
@import "start";

/*--------------------------------------------------------------
  ##  Choose
  --------------------------------------------------------------*/
@import "choose";

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
@import "widgets";

/*--------------------------------------------------------------
  ##  My Exprience
  --------------------------------------------------------------*/
@import "my-exprience";

/*--------------------------------------------------------------
  ##  footer
  --------------------------------------------------------------*/
@import "footer";

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@import "responsive";
