/*!
  Theme Name: GP
  Theme URI: http://www.gpthemes.co/products/gp/
  Author: Golden Plus Theme
  Author URI: http://www.gpthemes.co/
  Description: The Ultimate Financial / Business Template
  Version: 1.1.1
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: gp
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Loading
    ## Header
    ## Banner
    ## feature
    ## About
    ## template
    ## team
    ## Skills
    ## Countup
    ## Recent Work
    ## Follow
    ## Service
    ## Like
    ## Pricing
    ## Brand Slider
    ## Testimonial
    ## Testimonial
    ## Blog
    ## Contact
    ## Experience
    ## Portfolio
    ## Newsletter
    ## Blog Page
    ## Start
    ## Choose
    ## widgets
    ## My Exprience
    ## Fotter
    ## Responsive

  --------------------------------------------------------------*/


@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "section/section";
