.dt-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 800;
    transition: all 0.3s ease-in-out;
    padding: 15px 0;
    background: rgba(0,0,0,0.08);
    border-bottom: 1px solid rgba(255,255,255,0.2); }

.header-container {
    width: 90%;
    margin: 0 auto; }


.site-logo {
    float: left;

    .logo-white {
        display: block; }

    .logo-black {
        display: none; } }

nav {
    float: right;

    ul {
        background: rgba(0,0,0,0.9);
        padding: 20px 0; }

    .sf-menu {
        margin: 0;

        li {
            background-color: transparent;

            a {
                border: none;
                color: #FFF;
                padding: 17px 14px;
                font-size: 13px;
                font-weight: 500;
                i {
                    margin-left: 10px; }

                &.menu-option {
                    padding: 17px 5px;
                    font-size: 16px; }

                sup {
                    font-weight: 700;
                    color: $color_theme;
                    font-size: 14px; }

                &:hover {
                    color: $color_theme; } }

            &:hover, &.sfHover {
                background-color: transparent; }

            ul {
                width: 200px;
                padding: 20px;

                li {
                    background-color: transparent;
                    a {
                        padding: 9px 0; }

                    ul {
                        border-left: 2px solid $color_theme;
                        left: 112%;
                        top: -20px;
                        li {
                            a {
                                padding: 5px 0; } } } } } } } }

.sf-arrows {
    .sf-with-ul {
        padding-right: 10px; } }

nav {
    .sf-menu {
        background-color: transparent;
        > li {
            > a {
                text-transform: uppercase;
                color: #fff; }

            > ul {
                padding: 20px;
                top: 126%;

                li {
                    a {
                        i {

                            position: absolute;
                            right: 0;
                            top: 14px; } } } } } } }


.sf-menu > li:last-child > ul {
    left: auto;
    right: 0%;

    &:before {
        left: auto;
        right: 10%; } }

nav {
    .sf-menu {
        opacity: 1;
        transition: all 0.3s ease-in-out; } }

/*=========  Burgermenu  ==========*/

.nav-toggle {
    cursor: pointer; }

.menu-toggle {
    position: relative;
    width: 30px;
    height: 24px; }


.menu-toggle:hover {
    cursor: pointer; }

.menu-toggle > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    height: 2px;
    background-color: #FFF;
    transition: all 0.3s ease-in-out; }

.transparent-header:not(.menu-style-2-open) .site-header .menu-toggle > div {
    background-color: #FFF; }


.transparent-header.fix-header .site-header .menu-toggle > div {
    background-color: #2c2c2c; }

.menu-toggle > div:nth-child(2) {
    top: 40%;
    margin-top: -2px; }


.menu-toggle > div:nth-child(3) {
    top: 68%; }

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(1) {
    -webkit-transform: translateY(10px) rotate(135deg);
    -ms-transform: translateY(10px) rotate(135deg);
    transform: translateY(10px) rotate(135deg); }

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(2) {
    opacity: 0; }

.cbp-spmenu-push-toright .menu-toggle.active > div:nth-child(3) {
    -webkit-transform: translateY(-4px) rotate(-135deg);
    -ms-transform: translateY(-4px) rotate(-135deg);
    transform: translateY(-4px) rotate(-135deg);
    width: 100%;
    background-color: #FFF; }



/*=========  Sticky Menu  ==========*/

.navbar-small {
    position: fixed;
    top: 0;
    background: #FFF;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
    padding: 5px 0;
    border-bottom: 0;

    .site-logo {
        .logo-white {
            display: none; }

        .logo-black {
            display: block;
            img {
                width: 100%; } } }

    nav {
        > ul {
            background-color: transparent;
            opacity: 1;
            > li {
                > a {
                    color: #000; }

                > ul {
                    top: 110%; } } } } }




.visible-menu {
    .dt-mobile-header {
        left: 0; } }


.navbar-small {
    background: #fff; }



.search-wrapper {
    position: absolute;
    right: 24%;
    width: 56%;
    background: #FFF;
    top: 0;
    height: 90px;
    background: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: -1;

    input {
        width: 95%;
        padding: 10px 50px 10px 10px;
        height: 50px;
        font-size: 20px;
        margin: 20px auto;
        display: block;
        background-color: transparent;
        color: #FFF;
        border: 0;
        border-bottom: 1px solid rgba(255,255,255,0.3);
        outline: none; }

    .search-btn {
        position: absolute;
        top: 32px;
        right: 40px;
        color: #FFF;
        font-size: 20px; }

    &.search-open {
        opacity: 1;
        cursor: pointer;
        z-index: 1; } }

.navbar-small {
    .search-wrapper {
        background: #FFF;
        height: 70px;

        input {
            color: #000;
            border-color: #000;
            margin: 10px auto; }

        .search-btn {
            color: #000;
            top: 22px; } } }




/*=========  Mobile Menu  ==========*/

.mobile-header {
    display: none; }

.mobile-menu {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0; }


#accordian {
    background: #191919;
    color: white;
    height: 100%;

    ul {
        margin: 0;
        padding: 0; } }

#accordian h3 {
    background: #000;
    // background: linear-gradient(#003040, #002535)
    margin-bottom: 0;
    border-bottom: 1px solid #222; }


#accordian h3 a {
    padding: 0 10px;
    font-size: 12px;
    line-height: 34px;
    display: block;
    color: white;
    text-decoration: none;

    i {
        margin-right: 10px; } }


#accordian h3:hover {
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.7); }

#accordian li {
    list-style-type: none; }

#accordian ul ul li a,
#accordian h4 {
    color: white;
    text-decoration: none;
    font-size: 11px;
    line-height: 27px;
    display: block;
    padding: 0 15px;
    transition: all 0.15s;
    position: relative; }


#accordian ul ul li a:hover {
    background: #464646;
    border-left: 5px solid #FFF; }


#accordian ul ul {
    display: none; }


#accordian li.active>ul {
    display: block; }


#accordian ul ul ul {
    margin-left: 15px;
    border-left: 1px dotted rgba(0, 0, 0, 0.5); }


#accordian a:not(:only-child):after {
    content: "\f104";
    font-family: fontawesome;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 14px; }


#accordian .active>a:not(:only-child):after {
    content: "\f107"; }


/*=========  JPush menu  ==========*/
/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-left {
    left: -250px; }


.cbp-spmenu-right {
    right: -250px; }


.cbp-spmenu-left.cbp-spmenu-open {
    left: 0px;
    z-index: 99999; }


.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px; }


/* Push classes applied to the body */

.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0; }


.cbp-spmenu-push-toright {
    left: 250px; }


.cbp-spmenu-push-toleft {
    left: -250px; }


/* Transitions */

.cbp-spmenu,
.cbp-spmenu-push {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }


/*=========  Side Contact  ==========*/

.side-contact {
    width: 250px;
    background: #232323;
    position: fixed;
    top: 0;
    height: 100vh;
    padding: 20px 30px;
    z-index: 9999;
    overflow-y: auto; }


.quick-contact {
    margin-top: 100px;

    h3 {
        color: #FFF;
        margin-bottom: 20px; }
    form {
        input[type="text"], textarea {
            width: 100%;
            padding: 10px;
            background: transparent;
            color: #FFF;
            outline: none;
            border-radius: 4px;
            border: 1px solid $color_theme; }


        input[type="text"] {
            margin-bottom: 15px; }


        textarea {
            height: 150px; } } }


.cart-list {
    width: 250px !important; }



/*========= Page Loader  ==========*/

#pre-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFF;
    top: 0;
    left: 0;
    z-index: 99999; }

.cssload-box-loading {
    width: 49px;
    height: 49px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

.cssload-box-loading:before {
    content: '';
    width: 49px;
    height: 5px;
    background: rgb(0,0,0);
    opacity: 0.1;
    position: absolute;
    top: 58px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.58s linear infinite {
        -o-animation: shadow 0.58s linear infinite;
        -ms-animation: shadow 0.58s linear infinite;
        -webkit-animation: shadow 0.58s linear infinite;
        -moz-animation: shadow 0.58s linear infinite; } }

.cssload-box-loading:after {
    content: '';
    width: 49px;
    height: 49px;
    background: $color_theme;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    animation: cssload-animate 0.58s linear infinite {
        -o-animation: cssload-animate 0.58s linear infinite;
        -ms-animation: cssload-animate 0.58s linear infinite;
        -webkit-animation: cssload-animate 0.58s linear infinite;
        -moz-animation: cssload-animate 0.58s linear infinite; } }




@keyframes cssload-animate {
    17% {
        border-bottom-right-radius: 3px; }

    25% {
        transform: translateY(9px) rotate(22.5deg); }

    50% {
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 39px; }

    75% {
        transform: translateY(9px) rotate(67.5deg); }

    100% {
        transform: translateY(0) rotate(90deg); } }



@-o-keyframes cssload-animate {
    17% {
        border-bottom-right-radius: 3px; }

    25% {
        -o-transform: translateY(9px) rotate(22.5deg); }

    50% {
        -o-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 39px; }

    75% {
        -o-transform: translateY(9px) rotate(67.5deg); }

    100% {
        -o-transform: translateY(0) rotate(90deg); } }



@-ms-keyframes cssload-animate {
    17% {
        border-bottom-right-radius: 3px; }

    25% {
        -ms-transform: translateY(9px) rotate(22.5deg); }

    50% {
        -ms-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 39px; }

    75% {
        -ms-transform: translateY(9px) rotate(67.5deg); }

    100% {
        -ms-transform: translateY(0) rotate(90deg); } }



@-webkit-keyframes cssload-animate {
    17% {
        border-bottom-right-radius: 3px; }

    25% {
        -webkit-transform: translateY(9px) rotate(22.5deg); }

    50% {
        -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 39px; }

    75% {
        -webkit-transform: translateY(9px) rotate(67.5deg); }

    100% {
        -webkit-transform: translateY(0) rotate(90deg); } }



@-moz-keyframes cssload-animate {
    17% {
        border-bottom-right-radius: 3px; }

    25% {
        -moz-transform: translateY(9px) rotate(22.5deg); }

    50% {
        -moz-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 39px; }

    75% {
        -moz-transform: translateY(9px) rotate(67.5deg); }

    100% {
        -moz-transform: translateY(0) rotate(90deg); } }



@keyframes shadow {
    0%,
    100% {
        transform: scale(1, 1); }

    50% {
        transform: scale(1.2, 1); } }



@-o-keyframes shadow {
    0%,
    100% {
        -o-transform: scale(1, 1); }

    50% {
        -o-transform: scale(1.2, 1); } }



@-ms-keyframes shadow {
    0%,
    100% {
        -ms-transform: scale(1, 1); }

    50% {
        -ms-transform: scale(1.2, 1); } }



@-webkit-keyframes shadow {
    0%,
    100% {
        -webkit-transform: scale(1, 1); }

    50% {
        -webkit-transform: scale(1.2, 1); } }



@-moz-keyframes shadow {
    0%,
    100% {
        -moz-transform: scale(1, 1); }

    50% {
        -moz-transform: scale(1.2, 1); } }

