.team {
    position: relative;
    padding: 50px 0 80px;
    background-size: cover;
    background-repeat: no-repeat;

    .team-two-caption {
        margin-bottom: 50px;
        text-align: left;

        p {
            color: #000;
            line-height: 26px; } }

    .gallery-top,
    .gallery-thumbs {
        overflow: hidden; }

    .gallery-top {

        .swiper-slide {

            img {
                float: left; }

            .slider-content {
                margin-left: 310px;
                max-width: 550px;

                h4 {
                    font-size: 24px;
                    font-weight: 700;
                    color: $color_theme; }

                span {
                    color: #444;
                    display: block;
                    margin-bottom: 30px; }

                p {
                    color: #000;
                    line-height: 26px;
                    margin-bottom: 30px; } } }

        .banner-next,
        .banner-prev {
            right: 21.5%;
            height: 40px;
            width: 40px;
            border: 1px solid #000;
            position: absolute;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            i {
              font-size: 35px; }

            &:hover {
              background: $color_theme;
              border-color: transparent;

              i {
                color: #FFF; } } }

        .banner-next {
            top: auto; }

        .banner-prev {
            top: 20%;
            left: auto; } }

    .gallery-thumbs {
        width: 100px;
        height: 330px;
        position: absolute;
        right: 20%;
        top: 30%;

        .swiper-slide {
            height: 100%;
            opacity: 0.3;
            cursor: pointer; }

        .swiper-slide-active {
            opacity: 1; } } }



.gp-social-link {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        height: 35px;
        width: 35px;
        line-height: 36px;
        background: #444;
        text-align: center;
        transition: all 0.3s ease-in-out;
        a {
            color: #FFF;
            text-decoration: none;
            display: block; }

        &:hover {
            background: $color_theme; } } }

/*=========  Team two  ==========*/
.team-two {
    padding: 100px 0;
    text-align: center; }

.member {
    position: relative;
    cursor: pointer;
    img {
        width: 100%; }

    .team-overlay {
        position: absolute;
        width: 92.5%;
        height: 95%;
        left: 10px;
        top: 10px;
        padding: 10px;
        background: $color_rgba;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        .name {
            margin-top: 150px;
            h3 {
                color: #FFF;
                font-size: 22px;
                font-weight: 800;
                letter-spacing: 0.05em;
                transform: translateX(30px);
                transition: all 0.3s; }

            p {
                color: #000;
                font-weight: 700;
                font-size: 16px;
                transform: translateX(35px);
                transition: all 0.5s; } }

        p {
            transform: translateX(35px);
            transition: all 0.7s ease-in-out;
            color: #FFF; }

        .team-link {
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%);
            width: 100%;

            li {
                display: inline-block;
                height: 35px;
                width: 35px;
                background: rgba(0,0,0,0.5);
                line-height: 38px;
                transtion: all 0.3s ease-in-out;
                transform: translateY(25px);

                a {
                    font-size: 16px;
                    color: #FFF;
                    display: block;
                    transtion: all 0.3s ease-in-out; }

                &:nth-child(1) {
                    transition: all 0.3s; }

                &:nth-child(2) {
                    transition: all 0.5s; }

                &:nth-child(3) {
                    transition: all 0.7s; }

                &:nth-child(4) {
                    transition: all 0.9s; }

                &:nth-child(5) {
                    transition: all 1.1s; } } } }

    &:hover {
        .team-overlay {
            opacity: 1;

            .name {
                h3, p {
                    transform: translateX(0); } }

            p {
                transform: translateX(0); }

            .team-link {
                li {
                    transform: translateY(0);

                    &:hover {
                        background: rgba(255,255,255,0.5);

                        a {
                            color: #000; } } } } } } }


/*=========  Team Three  ==========*/
.team-three {
    padding: 100px 0;
    text-align: center; }


.team-container {
    overflow: hidden;
    position: relative;

    .swiper-slide {
        position: relative;

        .member {
            text-align: left;
            overflow: hidden;

            img {
                width: 100%;
                transition: all 0.3s ease-in-out;
                transform: scale(1); }

            .team-overlay {
                padding: 20px;

                .name {
                    margin-top: 50px; }



                .team-link {
                    width: 83%;
                    bottom: 70px;

                    li {
                        text-align: center; } } }

            &:hover {
                img {
                    transform: scale(1.1); } } } }


    .slider-control {
        opacity: 0;
        transtion: all 0.3s ease-in-out;

        .tm-next-btn, .tm-prev-btn {
            position: absolute;
            height: 40px;
            width: 40px;
            border: 1px solid #000;
            line-height: 47px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
            cursor: pointer;
            transition: all 0.3s ease-in-out;


            i {
                font-size: 30px;
                color: #000; }


            &:hover {
                background: $color_theme;
                border-color: transparent; } }


        .tm-next-btn {
            right: 0; } }


    &:hover {
        .slider-control {
            opacity: 1; } } }
