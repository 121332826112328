#newsletter {
	padding: 100px 0;
	background: $color_theme;
	text-align: center;
	background-size: cover;

	h2 {
		color: #FFF;
		text-transform: uppercase;
		font-size: 45px;
		font-weight: 900;
		margin-bottom: 30px;
		letter-spacing: 0.05em;
		font-family: Raleway; }

	i {
		margin-bottom: 20px;
		color: #FFF;
		font-size: 35px;
		display: block; }

	#form {
		input[type="text"] {

			background-color: #FFF;
			height: 46px;
			line-height: 45px;
			width: 400px;
			padding: 0 20px;
			outline: none;
			color: #000;
			font-size: 16px;
			transition: all 0.3s ease-in-out;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
			border: none; } }


	.gp-btn {
		border: 0;
		outline: none;
		background: #000;
		color: #fff;
		transition: all 0.3s ease-in-out;
		border-radius: 0;
		margin-left: -4px;
		width: 150px !important;
		height: 46px;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;

		i {
			margin-right: 10px; }

		&:hover {
			background: $color_theme !important;
			color: #FFF; } } }

