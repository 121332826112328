/*=========  Revolution Slideshow  ==========*/

.tp-caption.main-slide-title, .main-slide-title {
    font-weight: 900;
    color: #FFF;
    font-family: $font_primary; }

.tp-caption.main-slide-sub, .main-slide-sub {
  color: #FFF; }

.tp-caption.description, .description {
    color: #FFF;
    font-weight: 600; }

.tp-caption.rev-two-sub-title, .rev-two-sub-title {
  color: #FFF; }


.tp-caption.rev-two-subtitle, .rev-two-subtitle {
    color: #FFF;
    text-transform: uppercase; }

.tp-caption.rv-two-title, .rv-two-title {
    color: #FFF;
    font-weight: 700;
    font-family: $font_primary; }

.tp-caption .rev-three-title, .rev-three-title {
    font-weight: 700;
    color: #FFF;
    font-family: $font_primary; }

.tp-caption.gp-btn, .gp-btn {
  &:hover {
    background: #000;
    color: #FFF; } }

.tp-caption.name, .name {
  color: $color_theme; }

/*========= Banner Static Image  ==========*/
.tp-caption.name, .name {
    color: #000; }

.tp-caption.gp-btn .gp-btn {
    &:hover {
        background: #000;
        color: #FFF; } }


/*========= Banner Particles  ==========*/
.banner-particles {
    height: 100vh;
    background-size: cover;
    position: relative;

    .banner-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background: rgba(0,0,0,0.5);
        padding: 40px;

        h3 {
            color: #FFF;
            font-weight: 600;
            font-size: 20px; }

        h1 {
            color: $color_theme;
            font-weight: 900;
            font-size: 70px; }

        p {
            color: #FFF;
            font-size: 18px;
            margin-bottom: 20px; }

        .gp-btn {
            background: $color_theme;
            padding: 15px 30px;
            text-transform: capitalize;

            &:hover, &:focus {
                background: #FFF;
                color: #000;
                text-decoration: none; } } } }

