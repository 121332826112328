#work-process {
	padding: 100px 0 0;
	text-align: center;
	background: #e6e6e6;

	.work {
		padding: 50px;
		text-align: left;
		transition: all 0.3s ease-in-out;
		z-index: 1;

		h3 {
			color: $color_theme;
			text-transform: uppercase;
			font-size: 24px;
			margin-bottom: 10px;
			font-weight: 700; }


		p {
			color: #FFF;
			font-size: 16px;
			font-weight: 500; }

		a {
			display: inline-block;
			color: #fff;
			font-weight: 700;
			background: #252525;
			padding: 10px 10px;
			line-height: 14px;

			&:hover {
				background: $color_theme; } }


		&:hover {
			z-index: 2;
			transform: scale(1.1); } } }


.tp-caption {
	h1 {
		font-size: 50px;
		z-index: 100; } }
