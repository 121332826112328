// Fonts
$font_primary: 'Raleway', sans-serif;
$font_header: 'Roboto', sans-serif;

// Color
$color_theme: #f45c27;
$color_front: #111;
$color_theme-2: #f89406;
$color_theme-3: #642d88;
$color_text: #6d6c6c;
$color_header: #2c3e50;
$color_rgba: rgba(244,92,39,0.8);
