#like {
	padding: 100px 0;
	background-size: cover;
	text-align: center;
	position: relative;

	h2 {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 30px;
		color: #FFF; }


	p {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 30px;
		color: #FFF;
		letter-spacing: 0.05em; }


	.gp-btn {
		background: #FFF;
		color: #000;
		font-weight: 600;
		transition: all 0.3s ease-in-out;


		&:hover {
			background: $color_theme;
			color: #FFF; } } }
