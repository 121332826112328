#footer {
    background: #252525;
    padding: 50px 0 0;

    .footer-title {

        color: #FFF;
        text-transform: uppercase;
        margin-bottom: 30px; }

    p {
        color: #FFF;
        font-size: 13px;
        letter-spacing: 0.05em; }

    .gp-btn {
        margin-top: 30px; } }

.footer-two {
    padding: 100px 0 !important;
    text-align: center;

    h2 {
        color: #FFF;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 30px; }


    .gp-social-link {}

    li {
        overflow: hidden;
        height: 60px;
        width: 60px;
        a {
            i {
                position: relative;
                overflow: hidden;

                &:before, &:after {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: 24px;
                    position: absolute;
                    height: 60px;
                    width: 60px;
                    left: -30px;
                    line-height: 60px;
                    transition: all 0.2s ease-in-out; }

                &:after {


                    transform: translateY(50px); } }

            &:hover {
                i {
                    &:before {
                        transform: translateY(-50px); }

                    &:after {
                        transform: translateY(0); } } } }


        &.facebook {
            a {
                i {
                    &:before, &:after {
                        content: "\f09a"; } } }


            &:hover {
                background: #3b5998; } }


        &.twitter {
            a {
                i {
                    &:before, &:after {
                        content: "\f099"; } } }


            &:hover {
                background: #1da1f2; } }

        &.instagram {
            a {
                i {
                    &:before, &:after {
                        content: "\f16d"; } } }


            &:hover {
                background: radial-gradient(ellipse at 70% 70%,#ee583f 8%,#d92d77 42%,#bd3381 58%); } }

        &.linkedin {
            a {
                i {
                    &:before, &:after {
                        content: "\f0e1"; } } }


            &:hover {
                background: #008CC9; } }


        &.dribbble {
            a {
                i {
                    &:before, &:after {
                        content: "\f17d"; } } }


            &:hover {
                background: #ea4c89; } }


        &.behance {
            a {
                i {
                    &:before, &:after {
                        content: "\f1b4"; } } }


            &:hover {
                background: linear-gradient(#0096ff, #005dff); } } } }






.fotter-content {
    .latest-post {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #2f2f2f;

        &:last-child {
            border-bottom: none; }

        a {
            display: block;

            img {
                float: left; }

            .content {
                margin-left: 91px;
                h4 {
                    color: #FFF;
                    font-wight: 700;
                    transition: all 0.3s ease-in-out; }

                p {
                    color: #FFF;
                    margin-bottom: 0; } }

            &:hover {
                .content {
                    h4 {
                        color: $color_theme; } } } } } }

.flicker {
    a {
        display: inline-block;
        margin: 5px; } }



.copyright {
    padding: 22px;
    background: #000;
    text-align: center;

    p {
        color: #e3e3e3;
        font-size: 14px !important;
        margin-bottom: 0;
        letter-spacing: 0.05em;
        a {
            color: $color_theme;
            font-weight: 700;
            text-decoration: none; } } }





