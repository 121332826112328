#about {
    padding: 100px 0;
    background-size: cover;
    overflow: hidden;

    .about-thumb {
        img {
            width: 100%; } } }


.about-details {

    .section-title {
        color: #FFF;
        &:after {
            left: 0;
            transform: translateX(0); } }

    p {
        color: #FFF;
        font-size: 16px; } }

.who {
  .gp-btn {
      padding: 14px 30px;
      background: #333;
      color: #FFF;

      &:hover {
          background: $color_theme;
          color: #FFF; } } }


/*=========  About Two  ==========*/

#about {
    &.about-two {
        background: #efefef;
        padding: 100px 0;
        .about-details {

            .our-history {

                .section-title {
                    color: #000;
                    margin-top: 40px; }
                p {
                    color: #000;
                    font-size: 15px;
                    margin-bottom: 20px; } } }


        .aboute-thumb {
            box-shadow: 0 5px 40px rgba(0,0,0,0.2);

            img {
              width: 100%; } } } }

/*=========  About Three  ==========*/
.youtube-banner {
                height: 100%;
                overflow: hidden; }

.youtube-wrapper {
    position: relative;
    padding-bottom: 56.20%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #191a1c;
    cursor: pointer;


    &:before {
        content: "\f04b";
        font-family: 'FontAwesome';
        font-size: 34px;
        margin-top: 7px;
        margin-left: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        z-index: 2;
        cursor: pointer;
        visibility: visible;
        transform: translate3d(-50%, -50%, 0) scale(1);
        -moz-transform: translate3d(-50%, -50%, 0);
        -webkit-transform: translate3d(-50%, -50%, 0);
        transition: transform .2s, opacity .2s;
        -moz-transition: transform .2s, opacity .2s;
        -webkit-transition: transform .2s, opacity .2s; }


    &:after {
        content: '';
        position: absolute;
        margin-top: 6px;
        height: 90px;
        width: 90px;
        border: none;
        border-radius: 100px;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
        top: 50%;
        left: 50%;
        visibility: visible;
        transform: translate3d(-50%, -50%, 0) scale(1);
        -moz-transform: translate3d(-50%, -50%, 0);
        -webkit-transform: translate3d(-50%, -50%, 0);
        transition: background-color .2s, transform .2s, opacity 1.2s;
        -moz-transition: background-color .2s, transform .2s, opacity .2s;
        -webkit-transition: background-color .2s, transform .2s, opacity 1.2s; }


    &.reveal:after, &.reveal:before {
        opacity: 0;
        visibility: hidden;
        transition: visibility .4s, opacity .4s; }

    &.reveal {
        iframe {
            visibility: visible; } }

    iframe,object, embed {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        visibility: hidden; } }

.youtube-wrapper {
    .youtube-poster {
        height: 315px;
        background-position: center;
        background-size: cover; } }

/*=========  About me  ==========*/

#about.about-me {
    background: #eee;


    .section-title {
        margin-bottom: 50px; } }
.ab-thumb {
    padding: 10px;
    border: 3px solid #000;

    img {
        width: 100%; } }

.about-me {
    text-align: left;
    h3 {
        font-size: 24px;
        font-weight: 600;
        padding-top: 10px;
        margin-bottom: 30px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            width: 50px;
            height: 3px;
            background: $color_theme; } }

    p {
        font-weight: 500;
        margin: 20px 0 30px; }

    .gp-btn {
        margin-right: 15px; } }

.table {
    tbody {
        tr {
            border-bottom: 1px solid #ededed;
            td {
                border-color: #999;
                font-weight: 600;
                padding: 12px 0;
                font-size: 15px;

                span {
                    color: $color_theme;
                    font-weight: 700;
                    font-size: 16px; } } } } }
