.blog-banner {
    padding: 150px 0;
    background-size: cover;
    background-repeat: no-repeat; }


.blog-banner-caption {
    h3 {
        font-size: 45px;
        text-transform: uppercase;
        color: #FFF;
        font-weight: 800;
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        line-height: 40px;
        margin-bottom: 30px;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: #FFF; } }

    p {
        color: #FFF;
        font-weight: 700;
        font-size: 18px;
        font-style: italic; } }

/*=========  Blog Style One  ==========*/

.blog {
    padding: 70px 0;

    .post {
        margin-bottom: 30px; } }


/*=========  Blog style two  ==========*/
.blog-style-two {
    padding: 70px 0;
    .blog-post {
        margin-bottom: 50px;
        background: #f9f9f9;

        .post-thumb {
          overflow: hidden;
          img {
            width: 100%; } }

        .youtube-wrapper {
            .youtube-poster {
                height: 480px; } } }

    .blog-content {
        padding: 0 20px 20px; }

    .post-meta {
        margin: 20px 0;

        .meta {
            margin: 0;
            padding: 0;
            list-style: none;
            border-bottom: 1px solid #999;
            padding-bottom: 10px;

            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    font-weight: 600;
                    color: #505050;
                    i {
                        margin-right: 5px;
                        color: #000; }

                    &:hover {
                        color: $color_theme; } } } } }

    .blog-title {
        color: #000;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 600;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $color_theme; } }


    a {
        text-decoration: none;
        color: #000;
        font-weight: 600;

        &:hover {
            color: $color_theme; } } }


.page-control {
    margin: 0 auto;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin: 0 5px;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 35px;
        background: #f5f5f5;
        border-radius: 3px;

        &:hover , &.page-active {
            background: $color_theme;
            cursor: pointer;

            a {
                color: #FFF;
                display: block; } } } }
