#brand {
	padding: 80px 0;
	text-align: center;

	.brand-container {
		overflow: hidden;

		.swiper-slide {
			background: #f5f5f5; } } }

.brand-two {
  background: $color_theme;

  h2 {
    color: #fff;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 30px;
    text-transform: uppercase; }


  .client-thumb {
    background: #fff;
    margin-bottom: 20px; } }
