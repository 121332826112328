
/*=========  Search  ==========*/

.widget_dt_search {
    .search-form {
        width: 100%;

        label {
            position: relative;
            width: 270px;
            margin-bottom: 0; }

        .search-icon {
            position: absolute;
            right: 10px;
            top: 8px;
            cursor: pointer; } }

    input[type="search"] {
        width: 100%;
        border: 1px solid #eeeeee;
        outline: none;
        padding: 8px 20px 8px 8px;
        color: #111;
        font-size: 14px;
        background: #f5f5f5; }

    .search-submit {
        display: none; } }



/*=========  Recent Post  ==========*/

.widget_recent_entries {
    .resent-post {
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        .post-thumbs {
            float: left;
            border-radius: 5px;
            overflow: hidden; }


        .post-content {
            margin-left: 90px;

            h5 {
                font-size: 13px;

                margin-top: 0;
                a {
                    color: #111111;
                    line-height: 20px;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    font-weight: 700;
                    &:hover {
                        color: $color_theme; } } }

            .post-meta {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    display: inline;
                    padding-right: 10px;

                    a {
                        color: $color_theme;
                        text-decoration: none;

                        i {
                            padding-right: 10px; } } } } } } }

/*=========  Categories  ==========*/
.widget {
    margin-bottom: 30px;
    padding: 10px;
    background: #f7f7f7;
    border-radius: 3px;

    .widget-title {
        position: relative;
        font-size: 20px;
        color: #111;
        margin-top: 0;
        margin-bottom: 25px;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-weight: 700;

        &:after {
            content: '';
            position: absolute;
            width: 50px;
            height: 3px;
            left: 0;
            bottom: 0;
            background: $color_theme; } } }

.widget_nav_menu {

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding: 10px 0;
            border-bottom: 1px solid rgba(0,0,0,0.1);

            &:last-child {
                border-bottom: none; }
            a {
                color: #6d6c6c;
                text-decoration: none;
                transition: all 0.3s ease;
                font-weight: 600;
                i {
                    margin-right: 5px; }

                &:hover {
                    color: $color_theme; } } } } }


/*=========  Tags  ==========*/

/* Dt Tags */
.dt-tag {
    a {
        color: #fff;
        border: 1px solid #eee;
        text-decoration: none;
        padding: 3px 6px;
        display: inline-block;
        margin-bottom: 5px;
        // margin-right: 5px
        transition: all 0.3s ease;
        background: #000;
        font-size: 13px;

        &:hover {
            background: $color_theme;
            color: #FFF; } } }
