#portfolio {
    padding: 100px 0;
    position: relative;
    text-align: center;
    background: #f8f8f8;

    p {
        margin-bottom: 30px;
        font-size: 16px; } }

.portfolio-filter {
    margin: 0;
    padding-left: 15px;
    list-style: none;
    margin-bottom: 50px;

    li {
        display: inline-block;
        margin-right: 10px;

        a {
            padding: 12px 22px;
            border: 1px solid #ababab;
            color: #ababab;
            font-size: inherit;
            line-height: 10px;
            color: #000;
            font-weight: 600;
            letter-spacing: 0.05em;


            &:hover, &:focus {
                color: #FFF;
                background: $color_theme;
                border-color: transparent;
                text-decoration: none; } }

        &:first-child {
            a {

                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px; } }

        &:last-child {
            a {

                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px; } } } }


.gp-portfolio-gallery {
    margin-bottom: 40px; }

.gp-portfolio-item {
    width: 33%;
    padding: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img {
        width: 100%; }

    &.width-hulp {
      width: 66%; } }

.inner {
    position: relative;
    overflow: hidden;


    img {
        transform: scale(1);
        transition: all 0.3s ease-in-out; }

    .portfolio-overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: $color_rgba;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        // transform: scale(0)
        backface-visibility: hidden;


        h3 {
            position: absolute;
            left: -70px;
            top: 50%;
            transform: rotate(-90deg) translateY(-50%);
            margin: 0;
            transition: all 0.3s ease-in-out;
            text-transform: uppercase;
            color: #FFF;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.1em; }

        .option {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            a {
                text-align: center;
                height: 40px;
                width: 40px;
                background: #ededed;
                color: #000;
                line-height: 48px;
                font-size: 20px;
                transition: all 0.3s ease-in-out;
                margin-right: 5px;

                &:hover {
                    background: #000;
                    color: #fff;
                    text-decoration: none; }

                &:first-child {
                    transform: translateY(-20px); }

                &:last-child {
                    transform: translateY(20px); } } } }


    &:hover {
        img {
            transform: scale(1.1); }

        .portfolio-overlay {
            opacity: 1;
            transform: scale(1);

            &:before {
                height: 40px; }

            &:after {
                width: 40px; }

            h3 {
                left: 0; }

            a {
                &:first-child {
                    transform: translateY(0); }

                &:last-child {
                    transform: translateY(0); } } } } }





