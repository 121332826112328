#experience {
	padding: 70px 0;
	text-align: center;
	h2 {
		font-size: 50px;
		color: $color_theme;
		text-transform: uppercase;
		font-weight: 700; }

	p {
		font-size: 25px;
		margin-bottom: 30px; }

	.award {
		float: left;
		padding: 15px;
		// width: 33%
		height: 150px;
		width: 150px;

		img {
			width: 100%; } } }


.award-des {
	text-align: left;
	.award-title {
		font-size: 30px;
		margin-bottom: 30px; }

	p {
		font-size: 18px	!important;
		font-size: 500px; } }

/*=========  Business Exprience  ==========*/
.bs-exprience {
  padding: 70px 0;

  background: $color_theme;

  h3 {
    color: #FFF;
    font-weight: 300;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 30px;
    span {
      font-size: 30px;
      font-weight: 700; } }

  .gp-btn {
    display: inline-block; } }
