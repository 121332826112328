#follow {
  padding: 100px 0;
  background-size: cover;
  overflow: hidden;

  .section-title {
    color: #FFF; }

  p {
    color: #FFF;
    font-size: 16px;
    margin-bottom: 50px; } }


.connected-icons {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    width: 16%;
    a {
      transition: all 0.3s ease-in-out;

      span {
        height: 80px;
        width: 80px;
        background: rgba(255,255,255,0.6);
        border-radius: 50%;
        font-size: 40px;
        line-height: 80px;
        margin-bottom: 15px;
        color: #000;
        transition: all 0.3s ease-in-out;
        position: relative;
        backface-visibility: hidden;

        &:after {
          border: 2px solid $color_theme;
          left: -15px;
          right: -15px;
          top: -15px;
          bottom: -15px;
          transition: all 0.5s ease-in-out;
          content: '';
          opacity: 0;
          position: absolute;
          border-radius: 50%; } }

      h4 {
        color: $color_theme;
        letter-spacing: 0.05em;
        font-size: 20px; }

      &:hover {
        span {
          background: $color_rgba;
          color: #FFF;
          font-size: 30px !important;

          &:after {
            opacity: 1;
            left: -5px;
            right: -5px;
            top: -5px;
            bottom: -5px; } } } } } }
