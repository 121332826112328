#contact {
        padding: 100px 0;
        text-align: center; }


.contact-form {
        padding: 60px 20px 20px;
        position: relative;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);

        .contact-title {
                background: $color_theme;
                position: absolute;
                padding: 10px 20px;
                font-size: 20px;
                color: #FFF;
                left: 50%;
                top: -20px;
                transform: translateX(-50%);
                width: 250px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
                margin: 0;


                i {
                        margin-right: 10px; } }

        .form {
                p {
                        margin-bottom: 10px;
                        border-bottom: 1px solid #d4d4d4;
                        padding: 10px 0;

                        i {
                                margin-right: 10px;
                                float: left;
                                display: inline;
                                line-height: 25px;
                                font-size: 20px; }

                        input,  textarea {
                                border: none;
                                width: 90%;
                                display: inline;
                                outline: none;
                                font-size: 16px;
                                background-color: transparent; }


                        textarea {
                                height: 150px; } }
                .gp-btn {
                        margin: 0 auto;
                        display: block;
                        margin-top: 30px;
                        border: none;
                        outline: none; } } }


.adress {
        text-align: center;
        .con-icon {
                height: 70px;
                width: 70px;
                background: $color_theme;
                text-align: center;
                border-radius: 50%;
                line-height: 80px;
                margin: 0 auto;
                margin-bottom: 20px;
                box-shadow: 0 3px 11px rgba(0,0,0,0.4);

                i {
                        color: #FFF;
                        font-size: 30px; } }

        p {

                font-size: 16px; } }


.gmap3-area {
        height: 400px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.1); }


/*=========  Contact Two  ==========*/
#contact {
        &.contact-two {
                padding: 100px 0 0;

                .contact-wrapper {
                        margin-bottom: 50px; }

                .contact-form {
                        padding-top: 50px;
                        .form {
                                p {
                                        padding: 5px 0;
                                        margin-bottom: 5px;
                                        background-color: transparent;


                                        textarea {
                                                height: 120px; } }

                                .gp-btn {
                                        margin-top: 15px; } } }



                .adress {
                        margin-bottom: 51px;
                        padding: 20px 10px;
                        background: #f5f5f5;
                        text-align: left;
                        box-shadow: 0 6px 8px rgba(50,50,50,0.1);

                        .con-icon {
                                float: left;
                                margin-bottom: 0;
                                background: $color_theme; }

                        p {
                                margin-bottom: 0;
                                margin-top: 10px;
                                margin-left: 90px; } } } }


#map {
        .gmap3-area {
                margin-bottom: 0;
                height: 600px; } }
