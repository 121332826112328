@include font-face(OpenSans, '../fonts/OpenSans-Regular-webfont');
@include font-face(OpenSans, '../fonts/OpenSans-Bold-webfont', bold);

body {
    color: #333;
    font-family: $font_primary;
    font-size: 14px;
    line-height: 26px; }


h1,h2,h3,h4,h5 {
    font-family: $font_header;
    margin-top: 0; }

a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s ease-in-out; }

.gp-btn {
    color: #FFF;
    padding: 12px 20px;
    text-transform: uppercase;
    background: #000;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 500;
    line-height: 14px;

    &:hover {
        background: $color-theme;
        color: #FFF; } }

.read-more {
    background: #000;
    padding: 12px 20px 12px 20px;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    line-height: 14px;
    position: relative;
    display: inline-block;


    i {
        font-size: 16px;
        position: absolute;
        right: 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        top: 28%; }

    &:hover, &:focus {
        background: $color_theme;
        color: #FFF;
        padding-right: 30px;
        text-decoration: none;

        i {

            opacity: 1; } } }

.border-none {
    border: none !important; }

.starter-template {
    padding: 40px 15px;
    text-align: center; }

.sprite {
    display: inline-block;
    width: 32px;
    margin: 10px;

    &:hover {
        opacity: 0.8; } }

.screenreader {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important; }

svg {
    display: block; }
a {
    transition: all 0.15s ease-in-out; }
a,
a:hover {
    text-decoration: none; }


.grid-half {
    width: 50%;
    float: left; }

@media( max-width: 768px ) {
    .grid-half {
        width: 100%; } }


.margin-none {
    margin: 0; }

.padding-none {
    padding: 0; }

.section-title {
    margin-top: 0;
    margin-bottom: 20px;
    // text-transform: uppercase
    color: #000;
    font-weight: 700;
    font-size: 36px;
    position: relative;
    padding-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.05em;

    &:first-letter {
        color: $color-theme; }

    &:after {
        position: absolute;
        content: '';
        width: 30%;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        background: $color-theme; } }


.section-caption {
    font-size: 16px;
    color: #000;
    letter-spacing: 0.5px;
    position: relative;
    margin-bottom: 50px; }

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8); }

.Switcher__control {
  background-image: none;
  i {
    font-size: 20px;
    margin-top: 4px; } }

/*=========  Loader  ==========*/

/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: $color_theme;
  width: 42px;
  height: 42px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  z-index: 1000;


  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }

.back-top {
  display: block; }


/*=========  Page Loader  ==========*/

.loading-block {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1); }


.page-loaded .loading-block {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }


.loading-block .loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px; }

/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */


@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0) {
            transform: scale(0); }
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) {
            transform: scale(1); }
    opacity: 0; } }


@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0) {
            transform: scale(0); }
    opacity: 0; }
  5% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) {
            transform: scale(1); }
    opacity: 0; } }


.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px) {
      -ms-transform: translateY(-30px) {
          transform: translateY(-30px); } } }

.ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: -0.4s {
            animation-delay: -0.4s; } }

.ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: -0.2s {
            animation-delay: -0.2s; } }

.ball-scale-multiple > div {
    background-color: #b4b4b4;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both {
            animation-fill-mode: both; }
    position: absolute;
    left: -30px;
    top: 0px;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite {
            animation: ball-scale-multiple 1s 0s linear infinite; } }
