#feature {
        padding: 100px 0;
        background: #f5f5f5;
        overflow: hidden; }

.feature {

        .feature-item {
                padding: 30px 15px;
                backface-visibility: hidden;
                cursor: pointer;
                background: #FFF;
                box-shadow: 0 15px 20px 0 rgba(0,0,0, 0.1);
                transition: all .25s cubic-bezier(.53,.01,.18,1);

                .ab-icon {
                        height: 80px;
                        width: 80px;
                        margin: 0 auto;
                        text-align: center;
                        line-height: 95px;
                        margin-bottom: 20px;
                        border-radius: 50%;
                        position: relative;
                        color: #000;
                        -webkit-transition: color 0.3s;
                        -moz-transition: color 0.3s;
                        transition: color 0.3s;
                        float: left;
                        z-index: 1;

                        i {
                                font-size: 55px;
                                z-index: 2;
                                transition: all 0.3s ease-in-out; }

                        &:after {
                                pointer-events: none;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                content: '';
                                -webkit-box-sizing: content-box;
                                -moz-box-sizing: content-box;
                                box-sizing: content-box;
                                top: -2px;
                                left: -2px;
                                padding: 2px;
                                z-index: -1;
                                // background: #f5f5f5
                                -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
                                -moz-transition: -moz-transform 0.2s, opacity 0.2s;
                                transition: all 0.3s ease-in-out; } }

                .feature-content {
                        margin-left: 100px;

                        h3 {
                                position: relative;
                                font-size: 22px;
                                padding-bottom: 10px;
                                display: inline-block;

                                &:after {
                                        content: '';
                                        position: absolute;
                                        width: 30%;
                                        background: #000;
                                        bottom: 0;
                                        left: 0;
                                        height: 2px; } }

                        .gp-btn {
                                padding: 5px 20px;
                                font-size: 12px;
                                transition: all 0.3s ease-in-out;

                                i {
                                                display: none;
                                                color: #FFF;
                                                margin-left: 5px; }

                                &:hover {

                                        i {
                                                display: inline-block; } } } }

                &:hover {
                        box-shadow: 0 25px 25px rgba(0,0,0,.15);
                        transform: translateY(-10px);
                        .ab-icon {
                                i {
                                        font-size: 30px;
                                        color: #FFF; }
                                &:after {

                                        background: #000; } } } } }


#feature {
        &.feature-two {
                text-align: center;

                .section-caption {
                        margin-bottom: 100px; }

                .feature-item {
                        padding: 70px 20px 20px;
                        position: relative;
                        .ab-icon {
                                float: none;
                                top: -45px;
                                left: 50%;
                                margin: 0;
                                transform: translateX(-50%);
                                position: absolute;
                                background: #e4e4e4;
                                line-height: 133px;
                                line-height: 80px;
                                line-height: 105px;
                                backface-visibility: hidden;

                                i {
                                        font-size: 40px; } }




                        .feature-content {
                                margin-left: 0;

                                h3 {
                                        &:after {
                                                left: 50%;
                                                width: 50%;
                                                transform: translateX(-50%); } } }


                        &:hover {
                                .ab-icon {
                                        height: 80px;
                                        width: 80px;
                                        line-height: 95px;

                                        i {
                                                font-size: 30px; } } } }

                &.feature-three {
                        background: $color_theme;

                        .section-title {
                                color: #FFF;

                                &:first-letter {
                                        color: #000; }

                                &:after {
                                        background: #FFF; } } } }

        &.feature-four {
                padding: 20px 0;
                background-color: transparent;


                .feature-item {
                    padding: 20px 0;
                    border-right: 1px solid #ededed;


                    .ft-icon {
                            float: left;
                            height: 60px;
                            width: 60px;
                            line-height: 70px;
                            background: $color_theme;
                            text-align: center;
                            border-radius: 5px;

                            i {
                                    font-size: 30px;
                                    color: #FFF; } }

                    .content {
                            margin-left: 70px;

                            h3 {
                                    font-size: 20px;
                                    font-weight: 600;
                                    position: relative;
                                    padding-bottom: 10px;
                                    margin-bottom: 10px;

                                    &:after {
                                            position: absolute;
                                            content: '';
                                            left: 0;
                                            bottom: 0;
                                            width: 60px;
                                            height: 2px;
                                            background: #000; } } } } } }





