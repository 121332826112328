#start {
  padding: 70px 0;
  text-align: center;
  color: #FFF;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700; }

  p {
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 0.05em; }

  .gp-btn {
    background: #6f6f6f;
    color: #FFF;
    font-weight: 600;
    letter-spacing: 0.05em;
    padding: 14px 20px;

    &:hover {
      background: $color_theme; } } }
